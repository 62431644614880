import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';


Axios.interceptors.response.use((response: AxiosResponse) => {
    return response;
});

Axios.interceptors.request.use((request: AxiosRequestConfig) => {
    return request;
});

export default Axios;
