import { UserChatHistory } from "common/models/chat/chat-history.model";
import { IDocumentDetails, IDocumentPage, IPdfDocsData } from "common/models/chat/reference-document.model";

interface IChatState{
    userFeedbackState:any;
    pdfDocumentDetails:IDocumentDetails|undefined;
    handsOffAcknowledged:Array<{id:string,message:string}>;
    viewDocURL:string;
    viewDocGuid:string;
    viewDocPageNumbers : IDocumentPage[];
    isDocumentOpen : boolean;
    chatScrollHeight:any;
    agentLeavingAcknowledged:Array<{id:string,message:string}>;
    messageInputEnabled : boolean;
    messagesDisplayed : Array<string>;
    chatSearchResults : UserChatHistory[];
    searchMessages : UserChatHistory[]|undefined; 
    chatScrolled:boolean;
    documentsData: IPdfDocsData;
}

const initalChatState: IChatState = {
    userFeedbackState:undefined,
    pdfDocumentDetails:undefined,
    handsOffAcknowledged:[],
    viewDocURL:"",
    viewDocGuid:"",
    viewDocPageNumbers:[],
    isDocumentOpen:false,
    chatScrollHeight:undefined,
    agentLeavingAcknowledged:[],
    messageInputEnabled:false,
    messagesDisplayed:[],
    chatSearchResults:[],
    searchMessages:undefined,
    chatScrolled:false,
    documentsData: {
        documents: [],
        activityId: ''
    }
};

export { initalChatState };
