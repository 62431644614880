import React, { useEffect } from 'react';
import { useMsal, useAccount, useIsAuthenticated } from '@azure/msal-react';

const useAuthenticate = () => {
    const { inProgress,instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        instance.setActiveAccount(account);
        console.log('Account: ', account);
        //Dispatch for User details
    }, [isAuthenticated]);

    return [inProgress,account, isAuthenticated];
};

export default useAuthenticate;
