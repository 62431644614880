import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChatFeedbackRequest } from 'common/models/chat/chat-feedback-request.model';
import { UserChatHistory } from 'common/models/chat/chat-history.model';
import { IDocumentDetails, IDocumentPage, IPdfDocsData } from 'common/models/chat/reference-document.model';
import { initialUserState } from 'common/redux/store/user/user.state';
import { initalChatState } from './chat.state';


const chatSlice = createSlice({
    name: 'chat',
    initialState: initalChatState,
    reducers: {
        sendUserFeedback: (state,{ payload }: PayloadAction<IChatFeedbackRequest>) => {
        },
        sendUserFeedbackSuccess: (state, { payload }: PayloadAction<any>) => {
             state.userFeedbackState = payload;
        },
        sendUserFeedbackFailed: (state, { payload }: PayloadAction<any>) => {
            state.userFeedbackState = initalChatState.userFeedbackState;
            console.log("Errors",payload);
        },
        getPDFDetails: (state,{ payload }: PayloadAction<string>) => {
            
        },
        getPDFDetailsSuccess: (state, { payload }: PayloadAction<IDocumentDetails>) => {
            state.pdfDocumentDetails = payload;
        },
        getPDFDetailsFailed: (state, { payload }: PayloadAction<any>) => {
            state.pdfDocumentDetails = initalChatState.pdfDocumentDetails
            console.log("Errors",payload);
        },
        updateChatHandsOffState: (state,action: PayloadAction<{id:string,message:string}>) => {
            state.handsOffAcknowledged.push(action.payload)
        },
        updateChatDocUrlState: (state,action: PayloadAction<string>) => {
            state.viewDocURL = action.payload
        },
        updateChatDocGuidState: (state,action: PayloadAction<string>) => {
            state.viewDocGuid = action.payload
        },
        updateChatDocPageNumState: (state,action: PayloadAction<IDocumentPage[]>) => {
            state.viewDocPageNumbers = action.payload
        },
        updateDocOpenState: (state,action: PayloadAction<boolean>) => {
            state.isDocumentOpen = action.payload
        },
        updateChatScrollHeight:(state,action:PayloadAction<any>)=>{
            state.chatScrollHeight = action.payload
        },
        updateAgentLeavingAcknowledgedState: (state,action: PayloadAction<{id:string,message:string}>) => {
            state.agentLeavingAcknowledged.push(action.payload)
        },
        updateMessageInputState: (state,action: PayloadAction<boolean>) => {
            state.messageInputEnabled = action.payload
        },
        updateMessageDisplayed: (state,action: PayloadAction<string>) => {
            state.messagesDisplayed.push(action.payload)
        },
        updateChatResults : (state,action: PayloadAction<UserChatHistory>) =>{
            state.chatSearchResults.push(action?.payload)
        },
        updateChatSearchMessages : (state,action: PayloadAction<UserChatHistory[]|undefined>) =>{
            state.searchMessages = action?.payload
        },
        updateChatScrolled : (state,action: PayloadAction<boolean>) =>{
            state.chatScrolled = action?.payload
        },
        setPdfDocsData: (state, action: PayloadAction<IPdfDocsData>) => {
            state.documentsData.documents = action?.payload?.documents;
            state.documentsData.activityId = action?.payload?.activityId;
        },
        clearPdfDocsData: (state) => {
            state.documentsData.documents = [];
            state.documentsData.activityId = '';
        }

    }
});

export const chatActions = chatSlice.actions;

export default chatSlice.reducer;
