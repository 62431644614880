export class IUserSessionDetails {
    guid : string;
    emailId: string;
    displayName: string;
    isSuccess : boolean;
    userRoles : Array<string>;
    hasAccess : boolean;
    constructor(
        _guid = '',
        _emailId:'',
        _displayName = '',
        _isSuccess: false,
        _userRoles = [],
        _hasAccess=true
    ) {
        this.guid = _guid;
        this.emailId = _emailId;
        this.displayName = _displayName;
        this.isSuccess = _isSuccess;
        this.userRoles = _userRoles;
        this.hasAccess = _hasAccess;
    }
}
