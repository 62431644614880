import { ApiResult } from "common/models";
import { put, takeLatest } from "redux-saga/effects";
import { ChatBotService } from "./chat.service";
import { chatActions } from "./chat.slice";

const sendUserFeedback = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.sendUserFeedback(action?.payload);

    if (hasErrors) {
        yield put({
            type: chatActions.sendUserFeedbackFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type: chatActions.sendUserFeedbackSuccess.type,
            payload: value
        });
    }
};

const sendUserFeedbackWatch = function* () {
    yield takeLatest(chatActions.sendUserFeedback.type, sendUserFeedback);
};

const getPDFDocument = function* (action) {
    const { hasErrors, errors, value }: ApiResult<any> =
        yield ChatBotService.getPDFDocument(action?.payload);
    if (hasErrors) {
        yield put({
            type: chatActions.getPDFDetailsFailed.type,
            payload: errors
        });
    } else {
        yield put({
            type:  chatActions.getPDFDetailsSuccess.type,
            payload: value
        });
    }
};


const getPDFDocumentWatch = function* () {
    yield takeLatest(chatActions.getPDFDetails.type, getPDFDocument);
};


export {
    sendUserFeedbackWatch,getPDFDocumentWatch
};
