import { APP_CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { IChatFeedbackRequest } from "common/models/chat/chat-feedback-request.model";
import { RequestAccessModel } from "common/models/user/request-access.model";
const userServiceConfig = {
    
    getBotConversationToken: (): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.BOTFRAMEWORK}/${METHOD.TOKENS}`, 'POST'),

    setCurrentUserSession : (): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.USER}/${METHOD.SESSION}`, 'POST'),
    
    signOutUser :(): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.USER}/${METHOD.LOGOUT}`, 'POST'),

    requestAccess: (requestDetails:RequestAccessModel): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.USER}/${METHOD.REQUEST_ACCESS}`, 'POST','', requestDetails),  
    
};

export default userServiceConfig;