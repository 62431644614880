import React, { FC, useEffect, useState } from "react";
import Header from "modules/gpo/components/Header/Header";
import ChatBot from "modules/gpo/components/Chatbot/Chatbot";

import { logo } from "assets/images";

import "./GPOHome.scss";
type GPOHomeProps = {
};
const GPOHome : FC<GPOHomeProps> = ({
}: GPOHomeProps) => {
  return (
    <div className="gpo-chatbot-container">
      <Header
      showProfileImage={true}
      showSearch={true}
      />
      <ChatBot />
    </div>
  );
};

export default GPOHome;
