import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { HTTP_HEADER_KEY } from 'common/enums';
import { ApiResult } from 'common/models';
import Axios from './http-interceptor';

export abstract class BaseHTTPClient {
    protected static async get<T>(
        url: string,
        contextHeader: AxiosRequestHeaders = {}
    ): Promise<ApiResult<T>> {
        let result: ApiResult<T>;
        const axiosOpts: AxiosRequestConfig = {
            headers: {
                [HTTP_HEADER_KEY.ContentType]: 'application/json',
                ...contextHeader
            }
        };
        try {
            const axiosResult = await Axios.get(url, axiosOpts);
            result = new ApiResult<T>(axiosResult?.data, []);
        } catch (error) {
            result = new ApiResult<any>(null, error);
        }
        return result;
    }

    protected static async post<T>(
        url: string,
        data: any,
        contextHeader: AxiosRequestHeaders = {}
    ): Promise<ApiResult<T>> {
        let result: ApiResult<T>;
        const axiosOpts: AxiosRequestConfig = {
            headers: {
                [HTTP_HEADER_KEY.ContentType]: 'application/json',
                ...contextHeader
            }
        };
        try {
            const axiosResult = await Axios.post(url, data, axiosOpts);
            result = new ApiResult<T>(axiosResult?.data, []);
        } catch (error) {
            result = new ApiResult<any>(null, error);
        }

        return result;
    }

    protected static async put<T>(
        url: string,
        data: any,
        contextHeader: AxiosRequestHeaders = {}
    ): Promise<ApiResult<T>> {
        let result: ApiResult<T>;
        const axiosOpts: AxiosRequestConfig = {
            headers: {
                [HTTP_HEADER_KEY.ContentType]: 'application/json',
                ...contextHeader
            }
        };
        try {
            const axiosResult = await Axios.put(url, data, axiosOpts);
            result = new ApiResult<T>(axiosResult?.data, []);
        } catch (error) {
            result = new ApiResult<any>(null, error);
        }
        return result;
    }

    protected static async delete<T>(
        url: string,
        data: any,
        contextHeader: AxiosRequestHeaders = {}
    ): Promise<ApiResult<T>> {
        let result: ApiResult<T>;
        const axiosOpts: AxiosRequestConfig = {
            headers: {
                [HTTP_HEADER_KEY.ContentType]: 'application/json',
                ...contextHeader
            },
            data: data,
            timeout: 300000
        };
        try {
            const axiosResult = await Axios.delete(url, axiosOpts);
            result = new ApiResult<T>(axiosResult?.data, '');
        } catch (error) {
            result = new ApiResult<any>(null, error);
        }
        return result;
    }

    protected static async getBlob(
        url: string,
        contextHeader: { [key: string]: string } = {}
    ): Promise<ApiResult<Blob>> {
        let result: ApiResult<Blob>;
        const axiosOpts: AxiosRequestConfig = {
            headers: {
                [HTTP_HEADER_KEY.ContentType]: 'application/json',
                ...contextHeader
            },
            responseType: 'blob'
        };
        try {
            const axiosResult = await Axios.get(url, axiosOpts);
            result = new ApiResult<Blob>(axiosResult?.data, []);
        } catch (error) {
            result = new ApiResult<any>(null, error);
        }
        return result;
    }
}
