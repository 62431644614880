import { ApiConfig, ApiResult, IGraphUser } from "common/models";
import { GraphClient } from "./graph-client.service";
import { HttpClient } from "./http-client.service";
import { APP_CONTROLLER, METHOD } from 'common/config/endpoint.config';
import { IChatFeedbackRequest } from "common/models/chat/chat-feedback-request.model";
import { ICurrentUserDetails } from "common/models/user/current-user-details.model";
import { IDocumentPage } from "common/models/chat/reference-document.model";


export class UserService extends HttpClient {
    private static currentUserDetails: ICurrentUserDetails;
    public static async getUserProfile(): Promise<ApiResult<IGraphUser>> {
        return await GraphClient.getCurrentUserProfile();
    }

    public static async getCurrentUserImage(): Promise<ApiResult<Blob>> {
        return await GraphClient.getCurrentUserAvatar();
    }

    public static async getUserImage(userId:string): Promise<ApiResult<Blob>> {
        return await GraphClient.getUserAvatar(userId);
    } 

}
