import { right_arrow } from "assets/images";
import React, { FC, useEffect } from "react";
import "./Popup.scss";
import {
  IDocumentPage,
  IReferenceDocument,
} from "common/models/chat/reference-document.model";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { chatActions } from "modules/gpo/redux/chat";

type PopupProps = {
  similarityScore: number;
  documents: IReferenceDocument[];
  activityId: string;
  onViewDoc: (
    pageNum: IDocumentPage[],
    documentGuid: string,
    activityId: string
  ) => void;
};
const Popup: FC<PopupProps> = ({
  similarityScore,
  documents,
  activityId,
  onViewDoc,
}: PopupProps) => {
  const chatStateDispatch = useAppDispatch();
  const chatState = useAppSelector((state: RootState) => state.chat);

  useEffect(() => {
    chatStateDispatch(chatActions?.setPdfDocsData({ documents, activityId }));
  }, []);

  const getStatus = () => {
    if (similarityScore <= 60) {
      return (
        <span className={`status status--poor`}>
          {Math.round(similarityScore) + "%"}
        </span>
      );
    } else if (similarityScore > 60 && similarityScore < 80) {
      return (
        <span className={`status status--fair`}>
          {Math.round(similarityScore) + "%"}
        </span>
      );
    } else if (similarityScore >= 80) {
      return (
        <span className={`status status--excellent`}>
          {Math.round(similarityScore) + "%"}
        </span>
      );
    }
  };
  return (
    <React.Fragment>
      {!chatState.isDocumentOpen && (
        <div className="gpo-tooltip">
          <div className="gpo-tooltip__container">
            <div className="row">
              <span className="title">Confident score</span>
              {getStatus()}
            </div>
            <div className="row row--source-data">
              <span className="title">Source</span>
              <span className="pages">
                {Array.from(
                  { length: documents.length },
                  (_, index) => index + 1
                )?.map((num, index, arr) => (
                  <span>
                    {`Document-${num}${index !== arr.length - 1 ? ", " : ""}`}{" "}
                  </span>
                ))}
              </span>
            </div>
            <div className="row">
              <span></span>
              <span
                className="view"
                onClick={() =>
                  onViewDoc(
                    documents[0]?.pages,
                    documents[0]?.documentGuid,
                    activityId
                  )
                }
              >
                View in document <img src={right_arrow} />
              </span>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Popup;
