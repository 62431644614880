import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
  useLayoutEffect,
} from "react";
import { hooks } from "botframework-webchat-component";
import moment from "moment";

import {
  like,
  like_fill,
  gpo_logo,
  open_green,
  open_orange,
  open_red,
  logout,
} from "assets/images";

import "./ChatHistory.scss";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { chatHistoryActions } from "modules/gpo/redux/chatHistory";
import {
  ChatHistoryModel,
  UserChatHistory,
} from "common/models/chat/chat-history.model";
import { ChatMessageType } from "common/enums/chat-message-types.enum";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import { ChatMessages } from "common/enums/chat-messages.enum";
import HighlightText from "../HighlightText/HighlightText";
import Divider from "../Divider/Divider";
import { IDocumentPage } from "common/models/chat/reference-document.model";
import Popup from "../Popup/Popup";
import { chatActions } from "modules/gpo/redux/chat";

type ChatHistoryProps = {
  searchText?: string;
  onViewDoc: (
    pageNum: IDocumentPage[],
    documentGuid: string,
    activityId: string
  ) => void;
};
const ChatHistory: FC<ChatHistoryProps> = ({
  searchText,
  onViewDoc,
}: ChatHistoryProps) => {
  const chatHistoryState = useAppSelector(
    (state: RootState) => state.chatHistory
  );
  const chatHistoryDispatch = useAppDispatch();
  const [chatHistoryMessages, setChatHistoryMessages] = useState<
    UserChatHistory[]
  >([]);
  const [groupedChatHistory, setGroupedChatHistory] = useState<any>(null);
  const [showPopup, setShowPopup] = useState<string>("");
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    chatHistoryDispatch(chatHistoryActions?.getChatHistory(""));
  }, []);
  const handleClickOutside = (e: any) => {
    if (popupRef.current) {
      if (!popupRef.current.contains(e.target)) {
        setShowPopup("");
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  useEffect(() => {
    if (chatHistoryState?.chatHistory) {
      setChatHistoryMessages(
        chatHistoryState?.chatHistory?.clientChatHistories
      );
      if (chatHistoryState?.chatHistory?.clientChatHistories?.length) {
        const groupedArray =
          chatHistoryState?.chatHistory?.clientChatHistories?.reduce(function (
            obj,
            item
          ) {
            obj[checkDay(item.messageCreatedDate)] =
              obj[checkDay(item.messageCreatedDate)] || [];
            obj[checkDay(item.messageCreatedDate)].push(item);
            return obj;
          },
          {});
        setGroupedChatHistory(groupedArray);
      }
      chatHistoryState?.chatHistory?.clientChatHistories?.map(
        (history: UserChatHistory) => {
          const historyItem = JSON.parse(JSON.stringify(history));
          historyItem.fromHistory = true;
          chatHistoryDispatch(chatActions?.updateChatResults(historyItem));
        }
      );
    }
  }, [chatHistoryState?.chatHistory]);

  const checkDay = (date): string => {
    if (moment.utc(date)?.local().isSame(moment(), "day")) {
      return "Today";
    } else if (
      moment.utc(date)?.local().isSame(moment().subtract(1, "day"), "day")
    ) {
      return "Yesterday";
    } else {
      return moment.utc(date)?.local().format("dddd");
    }
  };

  return groupedChatHistory != null ? (
    <div className="gpo-chat-history">
      {Object.keys(groupedChatHistory)?.map(
        (key: keyof typeof groupedChatHistory, index) => (
          <>
            <React.Fragment key={index}>
              <Divider dividerText={key} />
              {groupedChatHistory[key]?.map((message: UserChatHistory, i) => (
                <React.Fragment
                  key={"user-chat-history_" + message?.messageGuid}
                >
                  {message?.messageType == ChatMessageType.BOTMESSAGE && (
                    <section
                      className="bot"
                      id={"history-" + message?.messageGuid}
                    >
                      <div>
                        <header>
                          <img src={gpo_logo} />
                          <div className="name">
                            <strong>Capital Edge AnswerBot</strong>
                            <span>
                              {checkDay(message?.messageCreatedDate)}{" "}
                              {moment
                                .utc(message?.messageCreatedDate)
                                ?.local()
                                .format("LT")}
                            </span>
                          </div>
                        </header>
                        <div className="message-section">
                          <div className="message-section__wrapper">
                            <HighlightText
                              searchText={searchText}
                              displayText={message?.messageText?.replace(
                                /\n/g,
                                "</br>"
                              )}
                              disableTextHighlight={true}
                            />
                            <div className="message-footer">
                              <div
                                className="gpo-icon"
                                onClick={(event) =>
                                  setShowPopup(message?.messageGuid)
                                }
                              >
                                {message?.additionalInfo &&
                                  JSON.parse(message?.additionalInfo)
                                    ?.results &&
                                  JSON.parse(message?.additionalInfo)
                                    ?.results[0] &&
                                  JSON.parse(message?.additionalInfo)
                                    ?.results[0]?.score != 0 && (
                                    <>
                                      {JSON.parse(message?.additionalInfo)
                                        ?.results[0]?.score <= 60 && (
                                        <img src={open_red} />
                                      )}
                                      {JSON.parse(message?.additionalInfo)
                                        ?.results[0]?.score > 60 &&
                                        JSON.parse(message?.additionalInfo)
                                          ?.results[0]?.score < 80 && (
                                          <img src={open_orange} />
                                        )}
                                      {JSON.parse(message?.additionalInfo)
                                        ?.results[0]?.score >= 80 && (
                                        <img src={open_green} />
                                      )}
                                    </>
                                  )}
                                {showPopup == message?.messageGuid && (
                                  <div className="gpo-pop-up">
                                    {message?.additionalInfo && (
                                      <div ref={popupRef}>
                                        <Popup
                                          onViewDoc={onViewDoc}
                                          similarityScore={
                                            JSON.parse(message?.additionalInfo)
                                              ?.results[0]?.score
                                          }
                                          activityId={message?.messageGuid}
                                          documents={
                                            JSON.parse(message?.additionalInfo)
                                              ?.results[0]?.documents
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {message?.isMessageLiked == null && (
                            <div className="user-action">
                              <button>
                                <img src={like} />
                              </button>
                              <button>
                                <img src={like} />
                              </button>
                            </div>
                          )}
                          {message?.isMessageLiked != null &&
                            message?.isMessageLiked && (
                              <div className="user-action">
                                <button>
                                  <img src={like_fill} />
                                </button>
                                <button>
                                  <img src={like} />
                                </button>
                              </div>
                            )}
                          {message?.isMessageLiked != null &&
                            !message?.isMessageLiked && (
                              <div className="user-action">
                                <button>
                                  <img src={like} />
                                </button>
                                <button>
                                  <img src={like_fill} />
                                </button>
                              </div>
                            )}
                        </div>
                        {/* <div className="message-footer">
                  <div className="gpo-icon" onClick={(event) => setShowPopup(message?.messageGuid)}>
                    {message?.additionalInfo && JSON.parse(message?.additionalInfo)?.results && JSON.parse(message?.additionalInfo)?.results[0] && JSON.parse(message?.additionalInfo)?.results[0]?.score!=0  && (
                      <>
                      {JSON.parse(message?.additionalInfo)?.results[0]?.score <= 60 &&(
                        <img src={open_red} />
                      )}
                      {JSON.parse(message?.additionalInfo)?.results[0]?.score > 60 && JSON.parse(message?.additionalInfo)?.results[0]?.score < 80 &&(
                        <img src={open_orange} />
                      )}
                      {JSON.parse(message?.additionalInfo)?.results[0]?.score >= 80 &&(
                        <img src={open_green} />
                      )}
                      </>
                    )}  
                    {showPopup==message?.messageGuid && (
                   <div className="gpo-pop-up">
                  {(message?.additionalInfo &&
                  <div ref={popupRef}>
                  <Popup
                    onViewDoc={onViewDoc}
                    similarityScore={JSON.parse(message?.additionalInfo)?.results[0]?.score}
                    activityId={message?.messageGuid}
                    documents={JSON.parse(message?.additionalInfo)?.results[0]?.documents}
                   />
                   </div>
                   )}
                  </div>
                  )}              
                  </div>
                  
                </div> */}
                      </div>
                    </section>
                  )}
                  {message?.messageType == ChatMessageType.CLIENTMESSAGE && (
                    <section
                      className="user"
                      id={"history-" + message?.messageGuid}
                    >
                      <header>
                        <div className="name">
                          <strong>You</strong>
                          <span>
                            {checkDay(message?.messageCreatedDate)}{" "}
                            {moment
                              .utc(message?.messageCreatedDate)
                              ?.local()
                              .format("LT")}
                          </span>
                        </div>
                      </header>
                      <HighlightText
                        searchText={searchText}
                        displayText={message?.messageText?.replace(
                          /\n/g,
                          "</br>"
                        )}
                        disableTextHighlight={true}
                      />
                    </section>
                  )}
                  {message?.messageType == ChatMessageType.EVENTMESSAGE &&
                    message?.messageText == ChatMessages.AGENT_JOINED && (
                      <section
                        className="new-agent"
                        id={"history-" + message?.messageGuid}
                      >
                        <div className="detail">
                          <ProfileImage profileId={message?.user?.guid} />
                          <span>
                            <strong>{message?.user?.displayName}</strong> joined
                            the chat
                          </span>
                          <span>
                            {checkDay(message?.messageCreatedDate)}{" "}
                            {moment
                              .utc(message?.messageCreatedDate)
                              ?.local()
                              .format("LT")}
                          </span>
                        </div>
                      </section>
                    )}
                  {message?.messageType == ChatMessageType.AGENTMESSAGE && (
                    <section
                      className="agent"
                      id={"history-" + message?.messageGuid}
                    >
                      <header>
                        <ProfileImage profileId={message?.user?.guid} />
                        <div className="name">
                          <strong>{message?.user?.displayName}</strong>
                          <span>
                            {checkDay(message?.messageCreatedDate)}{" "}
                            {moment
                              .utc(message?.messageCreatedDate)
                              ?.local()
                              .format("LT")}
                          </span>
                        </div>
                      </header>
                      <div className="message-section">
                        <div className="message-section__wrapper">
                          <HighlightText
                            searchText={searchText}
                            displayText={message?.messageText?.replace(
                              /\n/g,
                              "</br>"
                            )}
                            disableTextHighlight={true}
                          />
                          <div className="message-footer">
                            <div
                              className="gpo-icon"
                              onClick={(event) =>
                                setShowPopup(message?.messageGuid)
                              }
                            >
                              {message?.additionalInfo &&
                                JSON.parse(message?.additionalInfo)?.results &&
                                JSON.parse(message?.additionalInfo)
                                  ?.results[0] &&
                                JSON.parse(message?.additionalInfo)?.results[0]
                                  ?.score != 0 && (
                                  <>
                                    {JSON.parse(message?.additionalInfo)
                                      ?.results[0]?.score <= 60 && (
                                      <img src={open_red} />
                                    )}
                                    {JSON.parse(message?.additionalInfo)
                                      ?.results[0]?.score > 60 &&
                                      JSON.parse(message?.additionalInfo)
                                        ?.results[0]?.score < 80 && (
                                        <img src={open_orange} />
                                      )}
                                    {JSON.parse(message?.additionalInfo)
                                      ?.results[0]?.score >= 80 && (
                                      <img src={open_green} />
                                    )}
                                  </>
                                )}
                              {showPopup == message?.messageGuid && (
                                <div className="gpo-pop-up">
                                  {message?.additionalInfo && (
                                    <div ref={popupRef}>
                                      <Popup
                                        onViewDoc={onViewDoc}
                                        similarityScore={
                                          JSON.parse(message?.additionalInfo)
                                            ?.results[0]?.score
                                        }
                                        documents={
                                          JSON.parse(message?.additionalInfo)
                                            ?.results[0]?.documents
                                        }
                                        activityId={message?.messageGuid}
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {message?.isMessageLiked == null && (
                          <div className="user-action">
                            <button>
                              <img src={like} />
                            </button>
                            <button>
                              <img src={like} />
                            </button>
                          </div>
                        )}
                        {message?.isMessageLiked != null &&
                          message?.isMessageLiked && (
                            <div className="user-action">
                              <button>
                                <img src={like_fill} />
                              </button>
                              <button>
                                <img src={like} />
                              </button>
                            </div>
                          )}
                        {message?.isMessageLiked != null &&
                          !message?.isMessageLiked && (
                            <div className="user-action">
                              <button>
                                <img src={like} />
                              </button>
                              <button>
                                <img src={like_fill} />
                              </button>
                            </div>
                          )}
                      </div>
                      {/* <div className="message-footer">
                        <div
                          className="gpo-icon"
                          onClick={(event) =>
                            setShowPopup(message?.messageGuid)
                          }
                        >
                          {message?.additionalInfo &&
                            JSON.parse(message?.additionalInfo)?.results &&
                            JSON.parse(message?.additionalInfo)?.results[0] &&
                            JSON.parse(message?.additionalInfo)?.results[0]
                              ?.score != 0 && (
                              <>
                                {JSON.parse(message?.additionalInfo)?.results[0]
                                  ?.score <= 60 && <img src={open_red} />}
                                {JSON.parse(message?.additionalInfo)?.results[0]
                                  ?.score > 60 &&
                                  JSON.parse(message?.additionalInfo)
                                    ?.results[0]?.score < 80 && (
                                    <img src={open_orange} />
                                  )}
                                {JSON.parse(message?.additionalInfo)?.results[0]
                                  ?.score >= 80 && <img src={open_green} />}
                              </>
                            )}
                          {showPopup == message?.messageGuid && (
                            <div className="gpo-pop-up">
                              {message?.additionalInfo && (
                                <div ref={popupRef}>
                                  <Popup
                                    onViewDoc={onViewDoc}
                                    similarityScore={
                                      JSON.parse(message?.additionalInfo)
                                        ?.results[0]?.score
                                    }
                                    documents={
                                      JSON.parse(message?.additionalInfo)
                                        ?.results[0]?.documents
                                    }
                                    activityId={message?.messageGuid}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div> */}
                    </section>
                  )}
                  {message?.messageType == ChatMessageType.EVENTMESSAGE &&
                    message?.messageText == ChatMessages.AGENT_LEFT && (
                      <section
                        className="agent-left"
                        id={"history-" + message?.messageGuid}
                      >
                        <div className="center">
                          <img src={logout} />
                          <span>
                            <strong>{message?.user?.displayName}</strong> left
                            the chat
                          </span>
                        </div>
                      </section>
                    )}
                </React.Fragment>
              ))}
            </React.Fragment>
          </>
        )
      )}
      {chatHistoryMessages?.length &&
        groupedChatHistory &&
        !groupedChatHistory["Today"] && <Divider dividerText={"Today"} />}
    </div>
  ) : (
    <></>
  );
};

export default ChatHistory;
