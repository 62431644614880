// Dev config
// export const ENV_CONFIG = {
//     //CLIENT_ID: 'e4b17bed-b45f-47bf-88a1-71bb5d1eb45b',
//     CLIENT_ID: 'bccdc48a-8943-4326-86f0-b62b1403155d',
//     AUTHORITY: 'https://login.microsoftonline.com/ey.com/',
//     REDIRECT_URI: "https://localhost:3000",
//     CACHE_LOCATION: 'sessionStorage',
//     DOMAIN : 'ey.com',
//     API_SCOPE:'api://e4b17bed-b45f-47bf-88a1-71bb5d1eb45b/user_impersonation',
//     API_URI:'https://usedgpowap04.azurewebsites.net/api/',
//     SHOW_AGENT_TIMER:true,
//     AGENT_HANDOFF_TIMER:10000,
//     TYPEWRITER_SPEED:50,
//     AUTHORIZATION_ACCESS_REQUEST_DATA:"mailto:rajagopal.pallath@gds.ey.com?cc=bibitha.bahuleyan@gds.ey.com;prasanth.christurajan@gds.ey.com&subject=Request for Access to GPO Chatbot&body=Dear Admin,%0AKindly provide access to the application.",
//     SHOW_ALTERNATE_RESPONSE:true
//     //mail to can be configured as follows:
//     //"mailto:xyz@mail.com;abc@mail.com?cc=abc1@mail.com;abc2@mail.com&bcc=abc1@mail.com&subject=Subject here&body=Body-goes-here"
// };

// Prod config
export const ENV_CONFIG = {
    CLIENT_ID: 'bccdc48a-8943-4326-86f0-b62b1403155d',
    AUTHORITY: 'https://login.microsoftonline.com/ey.com/',
    REDIRECT_URI: 'https://ce-demo.ey.com/',
    CACHE_LOCATION: 'sessionStorage',
    DOMAIN : 'ey.com',
    API_SCOPE:'api://e4b17bed-b45f-47bf-88a1-71bb5d1eb45b/user_impersonation',
    API_URI:'https://usedgpolwap11.azurewebsites.net/api/',
    SHOW_AGENT_TIMER:true,
    AGENT_HANDOFF_TIMER:20000,
    TYPEWRITER_SPEED:'20',
    AUTHORIZATION_ACCESS_REQUEST_DATA:'mailto:jason.song@ey.com;huanhuan.yue@ey.com;abel.sabu1@ey.com;karthik.ramanathan1@gds.ey.com?subject=Request for Access to GPO Chatbot&body=Dear Admin,%0A%09Kindly provide access to the GPO Chatbot application.',
    SHOW_ALTERNATE_RESPONSE:'true'
   //mail to can be configured as follows:
   //mailto:xyz@mail.com;abc@mail.com?cc=abc1@mail.com;abc2@mail.com&bcc=abc1@mail.com&subject=Subject here&body=Body-goes-here" 
};