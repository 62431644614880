import React, { FC,useState } from "react";

import "./HighlightText.scss";

type HighlightTextProps = {
    displayText: string;
    searchText:string|undefined;
    highlightColor?:string,
    textBorderColor?:string,
    disableTextHighlight?:boolean,
    showTextOnHover?:boolean;
};
const HighlightText: FC<HighlightTextProps> = ({
    displayText,
    searchText,
    highlightColor,
    textBorderColor,
    disableTextHighlight,
    showTextOnHover
}: HighlightTextProps) => {
      const createHighlight = () => {
        let displayString = displayText;
        if(searchText && !disableTextHighlight){
        const regex = new RegExp(searchText, 'gi');
        //text = text.replace(/(<mark class="highlight">|<\/mark>)/gim, '');
        displayString = displayText.replace(regex, '<span class="highlight">$&</span>');
        }
        return  {__html: displayString};
      }
      return (
        <p title={showTextOnHover?displayText:''} style={{borderColor : textBorderColor?textBorderColor :'none'}} className={`displayText ${searchText && displayText?.toLowerCase().includes(searchText?.toLowerCase()) ? ' highlighted' : ''}`} dangerouslySetInnerHTML={
          createHighlight()}
         />
        
      )
}

export default HighlightText;