import { ApiResult, IGraphUser } from "common/models";
import { RequestAccessModel } from "common/models/user/request-access.model";
import { GraphClient } from "common/services/graph-client.service";
import { HttpClient } from "common/services/http-client.service";
import userServiceConfig from "./user.config";


export class UserService extends HttpClient {
    public static async getUserProfile(): Promise<ApiResult<IGraphUser>> {
        return await GraphClient.getCurrentUserProfile();
    }

    public static async getCurrentUserImage(): Promise<ApiResult<Blob>> {
        return await GraphClient.getCurrentUserAvatar();
    }
    public static async getBotConversationToken():Promise<ApiResult<any>>{
        return await this.invokeApi<any>(userServiceConfig.getBotConversationToken());
    }
    public static async getUserImage(userId:string): Promise<ApiResult<Blob>> {
        return await GraphClient.getUserAvatar(userId);
    } 

    public static async setCurrentUserSession():Promise<ApiResult<any>>{
        return await this.invokeApi<any>(userServiceConfig.setCurrentUserSession());
    }

    public static async signOutUser():Promise<ApiResult<any>>{
        return await this.invokeApi<any>(userServiceConfig.signOutUser());
    }
    public static async requestAccess(requestDetails:RequestAccessModel): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(userServiceConfig.requestAccess(requestDetails));
    }
}
