import { APP_CONTROLLER, METHOD } from "common/config/endpoint.config";
import { ApiConfig } from "common/models";
import { IChatFeedbackRequest } from "common/models/chat/chat-feedback-request.model";


const chatApiConfig = {
 
    sendUserFeedback: (feedbackRequest:IChatFeedbackRequest): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.CHAT}/${METHOD.FEEDBACK}`, 'POST','',feedbackRequest),
    
    getPDFDocument: (documentGuid:string): ApiConfig<any> =>
       new ApiConfig(`${APP_CONTROLLER.DOCUMENTS}/${documentGuid}`, 'GET'),
};

export default chatApiConfig;
