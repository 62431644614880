import "./ConnectingLoader.scss";

const ConnectingLoader = () => {
  return (
    <div className="connecting">
      <div className="container">
        <h4>Connecting to Bot <div className="status">
              <span></span>
              <span></span>
              <span></span>
            </div></h4>
        <div className="items">
          <div className="component PC">
            <div className="laptop-screen">
              <div className="laptop-content">
                <div className="code">
                  <div className="line line01"></div>
                  <div className="line line02"></div>
                  <div className="line line03"></div>
                  <div className="line line04"></div>
                  <div className="line line05"></div>
                  <div className="line line06"></div>
                  <div className="line line07"></div>
                  <div className="line line08"></div>
                  <div className="line line09"></div>
                  <div className="line line10"></div>
                  <div className="line line11"></div>
                  <div className="line line12"></div>
                  <div className="line line13"></div>
                  <div className="line line14"></div>
                  <div className="line line15"></div>
                </div>
              </div>
            </div>
            <div className="laptop-base">
              <div className="laptop-trackpad"></div>
            </div>
          </div>
          <div className="component signals">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="component server">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectingLoader;
