import createSagaMiddleware from 'redux-saga';
import { configureStore, EnhancedStore, MiddlewareArray } from '@reduxjs/toolkit';
import rootSaga from './root.saga';
import rootReducer from './root.reducer';

let store: EnhancedStore;

const configureAppStore = (): EnhancedStore => {
    const sagaMiddleware = createSagaMiddleware();
    store = configureStore({
        reducer: rootReducer,
        preloadedState: {},
        middleware: new MiddlewareArray().concat(sagaMiddleware),
        devTools: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    });
    sagaMiddleware.run(rootSaga);
    return store;
};

export default configureAppStore;

export { store };
export type AppDispatch = typeof store.dispatch;
