import { FC, useEffect, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { close_btn, maximize, reset } from "assets/images";
import {
  close_btn_black,
  up_arrow_disabled,
  down_arrow,
  chevronRight,
  up_arrow,
  down_arrow_disabled,
} from "assets/media/icons";
import { Document, Page, pdfjs } from "react-pdf";
import "./PDFViewer.scss";
import React from "react";
import Loader from "common/components/base/Loader/Loader";
import ReactDOM from "react-dom";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import {
  IDocumentDetails,
  IDocumentPage,
} from "common/models/chat/reference-document.model";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { chatActions } from "modules/gpo/redux/chat/chat.slice";

type PDFViewerProps = {
  onViewDoc: (
    pageNumbers: IDocumentPage[],
    documentGuid: string,
    activityId: string
  ) => void;
};

const PDFViewer: FC<PDFViewerProps> = ({ onViewDoc }: PDFViewerProps) => {
  const chatStateDispatch = useAppDispatch();
  const [fileStatus, setFileStatus] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [shown, setShown] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);
  const [documentName, setDocumentName] = useState("");
  const [documentGuid, setDocumentGuid] = useState("");
  const [currentDocIndex, setCurrentDocIndex] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const chatState = useAppSelector((state: RootState) => state.chat);
  const docsData = chatState.documentsData.documents;

  const shouldNextDocDisabled = currentDocIndex === docsData.length - 1;
  const shouldPrevDocDisabled = currentDocIndex === 0;
  const shouldPrevPageDisabled = currentPageIndex === 0;
  const shouldNextPageDisabled = currentPageIndex === pageNumbers.length - 1;

  useEffect(() => {
    if (chatState?.isDocumentOpen) {
      setFileLoading(true);
      const wrapperDiv = document.getElementById("gpo-custom-chat-wrapper");
      if (wrapperDiv) {
        wrapperDiv.classList.add("gpo-multiple-wrapper");
        const heightDiv = document.getElementById(
          "history-" + chatState?.chatScrollHeight
        )
          ? document.getElementById("history-" + chatState?.chatScrollHeight)
          : document.getElementById("live-chat_" + chatState?.chatScrollHeight);
        const messagesWrapper = document.getElementsByClassName(
          "gpo-custom-chat-messages"
        )[0];
        if (messagesWrapper && heightDiv) {
          heightDiv.scrollIntoView({ block: "end", inline: "nearest" });
        }
      }
    }
  }, [chatState?.isDocumentOpen]);

  useEffect(() => {
    if (
      chatState?.viewDocURL &&
      chatState?.viewDocPageNumbers &&
      chatState?.viewDocPageNumbers?.length
    ) {
      const pages = chatState?.viewDocPageNumbers.map(
        (item) => item.pageNumber
      );
      const sortedPages = pages.sort((a, b) => a - b);
      setPdfUrl(chatState?.viewDocURL);
      setPageNumbers(sortedPages);
    }
  }, [chatState?.viewDocURL, chatState?.viewDocPageNumbers]);

  useEffect(() => {
    if (chatState?.viewDocGuid) {
      setDocumentGuid(chatState?.viewDocGuid);
      getDocumentPDFURL();
    }
  }, [chatState?.viewDocGuid]);

  useEffect(() => {
    if (chatState?.pdfDocumentDetails) {
      setFileLoading(true);
      const documentDetails: IDocumentDetails = chatState?.pdfDocumentDetails;
      chatStateDispatch(
        chatActions.updateChatDocUrlState(documentDetails?.sasUrl)
      );
      setDocumentName(documentDetails?.documentName);
    }
  }, [chatState?.pdfDocumentDetails]);

  const getDocumentPDFURL = () => {
    chatStateDispatch(chatActions.getPDFDetails(chatState?.viewDocGuid));
  };

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setFileLoading(false);
    setFileStatus(true);
  };

  const handleCloseDoc = () => {
    setFileStatus(false);
    chatStateDispatch(chatActions?.updateDocOpenState(false));
    chatStateDispatch(chatActions?.updateChatDocGuidState(""));

    //clear data on viewer close
    chatStateDispatch(chatActions?.clearPdfDocsData());
    chatStateDispatch(chatActions.updateChatDocUrlState(""));
    setDocumentName("");
    setDocumentGuid("");
    setCurrentDocIndex(0);
    setCurrentPageIndex(0);
    setFileLoading(false);
    setPdfUrl("");

    const wrapperDiv = document.getElementById("gpo-custom-chat-wrapper");
    if (wrapperDiv) {
      wrapperDiv.classList.remove("gpo-multiple-wrapper");
    }
    const messagesWrapper = document.getElementsByClassName(
      "gpo-custom-chat-messages"
    )[0];
    const heightDiv = document.getElementById(
      "history-" + chatState?.chatScrollHeight
    )
      ? document.getElementById("history-" + chatState?.chatScrollHeight)
      : document.getElementById("live-chat_" + chatState?.chatScrollHeight);
    if (messagesWrapper && heightDiv) {
      heightDiv.scrollIntoView({ block: "end", inline: "nearest" });
    }
    // if(messagesWrapper){
    //   messagesWrapper.scroll({ top: messagesWrapper.scrollHeight});
    // }
  };

  const modalBody = () => (
    <div className="fullscreen-modal">
      <header>
        <span>{documentName}</span>
        <img src={close_btn} onClick={() => setShown(false)} />
      </header>
      <div className="fullscreen-modal__body">
        <Worker workerUrl={"/pdf.worker_3_5_127.js"}>
          <Viewer
            fileUrl={pdfUrl}
            initialPage={pageNumbers[currentPageIndex] - 1 || 0}
          />
        </Worker>
      </div>
    </div>
  );

  const handleViewDoc = (
    pageNumbers: IDocumentPage[],
    documentGuid: string,
    activityId: string
  ) => {
    onViewDoc(pageNumbers, documentGuid, activityId);
  };

  const handleNextDocument = () => {
    setFileLoading(true);
    const activityId = chatState.documentsData.activityId;

    if (currentDocIndex + 1 < docsData.length) {
      setCurrentDocIndex(currentDocIndex + 1);
      setCurrentPageIndex(0);
      handleViewDoc(
        docsData[currentDocIndex + 1]?.pages,
        docsData[currentDocIndex + 1]?.documentGuid,
        activityId
      );
    }
  };

  const handlePreviousDocument = () => {
    setFileLoading(true);
    const activityId = chatState.documentsData.activityId;

    if (currentDocIndex - 1 >= 0) {
      setCurrentDocIndex(currentDocIndex - 1);
      setCurrentPageIndex(0);
      handleViewDoc(
        docsData[currentDocIndex - 1]?.pages,
        docsData[currentDocIndex - 1]?.documentGuid,
        activityId
      );
    }
  };

  const goToPreviousPage = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPageIndex < pageNumbers.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  return (
    <React.Fragment>
      {chatState.isDocumentOpen && (
        <div className="gpo-pdf-viewer">
          <div className="doc">
            <div className="gpo-pdf-header">
              <div className="close_btn" onClick={handleCloseDoc}>
                <img src={close_btn_black} />
              </div>

              <div className="gpo-pdf-header__container-left">
                {documentName && pageNumbers.length ? (
                  <>
                    <p className="doc-details__container">
                      <span className="doc-name" title={documentName}>
                        {documentName}
                      </span>
                      <span className="page-numbers">
                        ( Pg- {pageNumbers.join(", ")} )
                      </span>{" "}
                    </p>
                    <div className="btn-wrapper">
                      <img
                        src={
                          shouldPrevPageDisabled ? up_arrow_disabled : up_arrow
                        }
                        onClick={goToPreviousPage}
                      />
                      <img
                        src={
                          shouldNextPageDisabled
                            ? down_arrow_disabled
                            : down_arrow
                        }
                        onClick={goToNextPage}
                      />
                    </div>
                    <p>
                      {currentPageIndex + 1} of {pageNumbers.length || 1}
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div className="gpo-pdf-header__container-right">
                <div className="navigate-btns">
                  <button
                    className={`prev-btn ${
                      shouldPrevDocDisabled ? "prev-btn__disabled" : ""
                    }`}
                    disabled={shouldPrevDocDisabled}
                    onClick={handlePreviousDocument}
                    title={shouldPrevDocDisabled ? "" : "Previous document"}
                  >
                    <img src={chevronRight} />
                  </button>

                  <p className="navigate-btns__label">
                    <span>Document</span>
                    <span className="font-bold">
                      {currentDocIndex + 1}
                    </span> of{" "}
                    <span>
                      {chatState.documentsData?.documents?.length || 1}
                    </span>
                  </p>

                  <button
                    className={`next-btn ${
                      shouldNextDocDisabled ? "next-btn__disabled" : ""
                    }`}
                    disabled={shouldNextDocDisabled}
                    onClick={handleNextDocument}
                    title={shouldNextDocDisabled ? "" : "Next document"}
                  >
                    <img src={chevronRight} />
                  </button>
                </div>

                <div
                  className={`maximize ${
                    !fileStatus ? "maximize__disabled" : ""
                  }`}
                >
                  <img src={maximize} onClick={() => setShown(true)} />
                </div>
              </div>
            </div>
            {fileLoading && <Loader />}
            <Document
              file={pdfUrl}
              options={{ workerSrc: "/pdf.worker.js" }}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumbers[currentPageIndex]} />
            </Document>
          </div>
        </div>
      )}
      {shown && ReactDOM.createPortal(modalBody(), document.body)}
    </React.Fragment>
  );
};

export default memo(PDFViewer);
