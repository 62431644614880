import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from "@azure/msal-browser";
import reportWebVitals from "./reportWebVitals";
import MsalAuthProvider from "common/components/custom/MsalAuthProvider";
import App from "./App";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'
import configureStore from "common/redux/core/configure-store";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={configureStore()}>
  <BrowserRouter>
    <MsalAuthProvider>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={{
          scopes: ['User.Read']
        }}>
        <App />
      </MsalAuthenticationTemplate>
    </MsalAuthProvider>
  </BrowserRouter>
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
