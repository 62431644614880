export const APP_CONTROLLER = {
    BOTFRAMEWORK: 'BotFramework',
    CHAT:'Chat',
    USER:'User',
    DOCUMENTS:'Documents'
};

export const METHOD = {
    TOKENS: 'tokens',
    FEEDBACK:'feedback',
    SESSION:'session',
    LOGOUT:'logout',
    HISTORY:'history',
    REQUEST_ACCESS:'request-access'
};