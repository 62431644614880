import { FC, ReactElement } from 'react';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from 'common/config/auth.config';

type MsalAuthProviderProps = {
    children: ReactElement;
};

const MsalAuthProvider: FC<MsalAuthProviderProps> = ({ children }: MsalAuthProviderProps) => {
    return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default MsalAuthProvider;
