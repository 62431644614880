import { Configuration, PublicClientApplication } from '@azure/msal-browser';

import { ENV_CONFIG } from 'common/config/env.config';
import { GRAPH_SCOPES } from './app.config';
/**
 * Configuration object to be passed to MSAL instance on creation.
 */
const msalConfig: Configuration = {
    auth: {
        clientId: ENV_CONFIG.CLIENT_ID,
        authority: ENV_CONFIG.AUTHORITY,
        redirectUri: ENV_CONFIG.REDIRECT_URI
    },
    cache: {
        cacheLocation: ENV_CONFIG.CACHE_LOCATION,
        storeAuthStateInCookie: false
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const protectedResources = {
    graph: {
        endpoint: 'https://graph.microsoft.com/v1.0',
        scopes: [GRAPH_SCOPES.USER_READ]
    },
    api: {
        endpoint: ENV_CONFIG.API_URI,
        scopes: [ENV_CONFIG.API_SCOPE]
    },
};
