import React, { useEffect, useRef, useState } from "react";
import Typewriter from "typewriter-effect";
import TypeWriterEffect from "react-typewriter-effect";
import "./Attachment.scss";
import { text } from "body-parser";
import { ENV_CONFIG } from "common/config/env.config";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "common/redux/core/root.reducer";
import { chatActions } from "modules/gpo/redux/chat";

const Attachment = ({
  content,
  contentUrl,
  contentType,
  name,
  gpoRole,
  chatID,
  hasHandsOff,
  showTyping,
  stopTypingOnTabSwitch,
  isWelcomeMessage,
}: any) => {
  const chatState = useAppSelector((state: RootState) => state.chat);
  const chatStateDispatch = useAppDispatch();
  const [chatScrolled, setChatScrolled] = useState(false);

  const envSpeed: any = ENV_CONFIG.TYPEWRITER_SPEED;
  const typeWritingSpeed = parseInt(envSpeed);
  let i = 0;
  let displayString = content?.split(" ");
  const clearTimerRef: any = useRef();

  useEffect(() => {
    if (stopTypingOnTabSwitch) {
      clearTimeout(clearTimerRef?.current);
      const para = document.getElementById("live-chat_" + chatID);
      const textSpan = para?.querySelector(".content");
      if (textSpan) {
        textSpan.innerHTML = content.replace(/\n/g, "</br>");
        chatStateDispatch(chatActions?.updateMessageDisplayed(chatID));
        if (!hasHandsOff) {
          chatStateDispatch(chatActions?.updateMessageInputState(true));
        }
        const textSpanCursor = para?.querySelector(".add-cursor-animate");
        if (textSpanCursor) {
          textSpanCursor?.classList?.add("hide");
        }
        scrollToBottom();
      }
    }
  }, [stopTypingOnTabSwitch]);

  useEffect(() => {
    setChatScrolled(chatState?.chatScrolled);
  }, [chatState?.chatScrolled]);

  useEffect(() => {
    const para = document.getElementById("live-chat_" + chatID);
    const textSpan = para?.querySelector(".content");
    const cursor = para?.querySelector(".add-cursor-animate");
    if (cursor) {
      cursor?.classList?.remove("hide");
    }
    if (textSpan) {
      textSpan.innerHTML = "";
      chatStateDispatch(chatActions?.updateChatScrolled(false));
      if (isWelcomeMessage) {
        const delayTypingFn = setTimeout(() => {
          typeWriter();
        }, 100);
        return () => clearTimeout(delayTypingFn);
      } else {
        typeWriter();
      }
    }
  }, [content, chatID]);
  const typeWriter = () => {
    if (i < content?.length) {
      const para = document.getElementById("live-chat_" + chatID);
      const textSpan = para?.querySelector(".content");

      if (textSpan) {
        if (content.charAt(i) == "\n") {
          textSpan.innerHTML += "</br>";
          i++;
        } else {
          if (content?.charAt(i) == "<" && content?.charAt(i + 1) == "a") {
            const anchorTagEnd = content?.indexOf("</", i);
            if (anchorTagEnd != -1) {
              const anchorTag = content?.substring(i, anchorTagEnd + 4);
              textSpan.innerHTML += anchorTag;
              i = anchorTagEnd + 4;
            } else {
              textSpan.innerHTML += content.charAt(i);
              i++;
            }
          } else {
            textSpan.innerHTML += content.charAt(i);
            i++;
          }
        }
        clearTimerRef.current = setTimeout(typeWriter, typeWritingSpeed);
      }
    }
    if (i == content.length) {
      chatStateDispatch(chatActions?.updateMessageDisplayed(chatID));
      if (!hasHandsOff) {
        chatStateDispatch(chatActions?.updateMessageInputState(true));
      }
      const para = document.getElementById("live-chat_" + chatID);
      const textSpan = para?.querySelector(".add-cursor-animate");
      if (textSpan) {
        textSpan?.classList?.add("hide");
      }
    }
    scrollToBottom();
  };
  const scrollToBottom = () => {
    const messagesWrapper: any = document.getElementsByClassName(
      "gpo-custom-chat-messages"
    )[0];
    if (messagesWrapper) {
      messagesWrapper.scroll({ top: messagesWrapper.scrollHeight });
    }
  };
  switch (contentType) {
    case "image/gif":
    case "image/jpeg":
    case "image/png":
    case "image/svg":
    case "image/svg+xml":
      return <img alt={name} src={contentUrl} />;

    case "text/markdown":
    case "text/plain": // return <p>{content}</p>;

    {
      //   return (
      //   <p><TypeWriterEffect

      //   startDelay={100}
      //   cursorColor="black"
      //   multiText ={[content]}
      //   multiTextDelay={1000}
      //   typeSpeed={100}
      // /></p>
      // );
      if (gpoRole == "bot") {
        // return (<p id={chatID}></p>
        // return (
        //   <div className="typewriter">
        //     <p>{content}</p>
        //   </div>
        // )
        //  )
        //return <p>{content}</p>
        // return (
        //    <TypeWriterEffect
        //     startDelay={100}
        //     cursorColor="#FFE600"
        //     multiText ={[content]}
        //     multiTextDelay={1000}
        //     typeSpeed={35}
        //   />

        // );
        return (
          <>
            {(showTyping == undefined || showTyping) && (
              <p className="text-message" id={"live-chat_" + chatID}>
                <span className="content"></span>
                <span className="add-cursor-animate"></span>
              </p>
            )}
            {showTyping != undefined && !showTyping && (
              <p id={"live-chat_" + chatID}>{content}</p>
            )}
          </>
        );
      } else {
        return <p id={"live-chat_" + chatID}>{content}</p>;
      }
    }
    default:
      if (contentUrl) {
        return (
          <a href={contentUrl} rel="noopener noreferrer" target="_blank">
            {name}
          </a>
        );
      } else {
        return <pre>{JSON.stringify(content, null, 2)}</pre>;
      }
  }
};

export default Attachment;
