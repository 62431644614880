import { getPDFDocumentWatch, sendUserFeedbackWatch } from 'modules/gpo/redux/chat/chat.saga';
import { getChatHistoryWatch, getSearchResultsWatch } from 'modules/gpo/redux/chatHistory/chatHistory.saga';
import { all } from 'redux-saga/effects';
import { getBotConversationTokenWatch, getCurrentUserAvatarWatch, getUserAvatarWatch, getUserProfileWatch, requestAccessWatch, setCurrentUserSessionWatch, signOutUserWatch } from '../store/user/user.saga';

export default function* rootSaga() {
    yield all([
        getUserProfileWatch(),
        getCurrentUserAvatarWatch(),
        getUserAvatarWatch(),
        getBotConversationTokenWatch(),
        setCurrentUserSessionWatch(),
        signOutUserWatch(),
        sendUserFeedbackWatch(),
        getPDFDocumentWatch(),
        getChatHistoryWatch(),
        getSearchResultsWatch(),
        requestAccessWatch()
    ]);
}
