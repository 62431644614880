import { ChatHistoryModel } from "common/models/chat/chat-history.model";

interface IChatHistoryState{
  chatHistory:ChatHistoryModel|undefined;
  searchTriggered:boolean;
  searchValue:string;
  searchResults:ChatHistoryModel|undefined;
}

const initialChatHistoryState: IChatHistoryState = {
    chatHistory:undefined,
    searchTriggered:false,
    searchValue:"",
    searchResults:undefined
};

export { initialChatHistoryState };
