import { FC } from "react";
import "./Divider.scss";

type DividerProps = {
  dividerText: any;
};
const Divider: FC<DividerProps> = ({
  dividerText
}: DividerProps) => {
  return <div className="divider">
      <div className="divider__container">
          <span>{dividerText}</span>
      </div>
  </div>;
};

export default Divider;
