import { ApiResult } from "common/models";
import { HttpClient } from "common/services/http-client.service";
import chatHistoryApiConfig from "./chatHistory.config";

export class ChatHistoryService extends HttpClient {
    public static async getChatHistory(searchText:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.getChatHistory(searchText));
    }
    public static async getSearchResults(searchText:string): Promise<ApiResult<any> | undefined> {
        return await this.invokeApi<any>(chatHistoryApiConfig.getChatHistory(searchText));
    }
    
    
}
