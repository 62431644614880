import { close_grey, gpo_logo, open_green, profile_logout } from "assets/images";
import "./Messages.scss";
import { RootState, useAppDispatch, useAppSelector } from "common/redux/core/root.reducer";
import { chatHistoryActions } from "modules/gpo/redux/chatHistory";
import { ChatHistoryModel, UserChatHistory } from "common/models/chat/chat-history.model";
import { useEffect, useRef, useState } from "react";
import React from "react";
import moment from "moment";
import ProfileImage from "common/components/custom/ProfileImage/ProfileImage";
import HighlightText from "../HighlightText/HighlightText";
import { ChatMessageType } from "common/enums/chat-message-types.enum";
import { chatActions } from "modules/gpo/redux/chat";

const Messages = () => {
  const chatHistoryState = useAppSelector((state: RootState) => state.chatHistory);
  const chatState = useAppSelector((state: RootState) => state.chat);
  const chatHistoryDispatch = useAppDispatch(); 
  const searchRef:any = useRef(); 
  const [searchInput, setSearchInput] = useState<string>("");
  const [chatHistoryMessages, setChatHistoryMessages] = useState<UserChatHistory[]>([]);
  const [selectedSearchResult, setSelectedSearchResult] = useState<UserChatHistory>();
  useEffect(()=>{
    if(chatState?.searchMessages){
       setChatHistoryMessages(chatState?.searchMessages);
       const wrapper = document.getElementsByClassName("gpo-custom-chat-container")[0];
       if(wrapper){
        wrapper?.classList?.add("message-list");
       }
       if(chatState?.searchMessages?.length){
        selectSearchResult(chatState?.searchMessages[0]);
       }
       else{
        setSelectedSearchResult(undefined);
       }
       const searchInputValue:any = document?.getElementById("history-search");
       if(searchInputValue){
        setSearchInput(searchInputValue?.value);
       }
      }  
  },[chatState?.searchMessages])

  const selectSearchResult= (searchItem:UserChatHistory) => {
    setSelectedSearchResult(searchItem);
    
    const chatHistoryElement = document.querySelector(".gpo-chat-history");
    const selectedSearchChat = document?.querySelector(".selectedSearch");
    if(selectedSearchChat){
      selectedSearchChat?.classList?.remove("selectedSearch");
    }
    const selectedChat = searchItem?.fromHistory ? chatHistoryElement?.querySelector("#history-"+searchItem?.messageGuid): document.getElementById('live-chat_'+searchItem?.messageGuid);
    
    if(selectedChat){
      if(searchItem?.fromHistory){
      selectedChat?.querySelector("p.displayText")?.classList?.add("selectedSearch");
      selectedChat.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
      }
      else{
        selectedChat?.classList?.add("selectedSearch");
        selectedChat.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});
      }
    }
  }
  const scrollToBottom = () =>{
    const messagesWrapper = document.getElementsByClassName('gpo-custom-chat-messages')[0];
    if(messagesWrapper){
      messagesWrapper.scroll({ top: messagesWrapper.scrollHeight,behavior:'smooth'});
    }
  }
  const clearSearch = () =>{
    const searchElem:any = document.getElementById('history-search');
    if(searchElem){
      searchElem.value = "";
    }
    
   // const chatHistoryElement = document.querySelector(".gpo-chat-history");
    const selectedSearchChat = document?.querySelector(".selectedSearch");
    if(selectedSearchChat){
      selectedSearchChat?.classList?.remove("selectedSearch");
    }
    chatHistoryDispatch(chatActions?.updateChatSearchMessages(undefined));   
    const wrapper = document.getElementsByClassName("gpo-custom-chat-container")[0];
    if(wrapper){
      wrapper?.classList?.remove("message-list");
    }
    scrollToBottom();
    //chatHistoryDispatch(chatHistoryActions?.updateSearchValue(searchInput));
    //chatHistoryDispatch(chatHistoryActions?.updateSearchTriggered(false));
  }

  return chatState?.searchMessages ?(
    <div className="messages">
      <div className="messages__container">
        <header>
          <span>Messages</span>
          <img src={close_grey} className="close" 
           onClick={(event:any) => {clearSearch()}}
           />
        </header>
        <div className="list-wrapper">
         {chatHistoryMessages && chatHistoryMessages?.map((message:UserChatHistory, i) => 
          <React.Fragment key={message?.messageGuid}>
          <div className={selectedSearchResult?.messageGuid==message?.messageGuid?"list active":"list"}
            onClick={(event:any) => {selectSearchResult(message)}}
           >
            {(message?.messageType == ChatMessageType?.AGENTMESSAGE || message?.messageType == ChatMessageType?.CLIENTMESSAGE) &&(
              <ProfileImage 
              profileId={message?.user?.guid}
              />
            )}
             {message?.messageType == ChatMessageType?.BOTMESSAGE &&(
             <img src={gpo_logo} alt="" />
             )}
            <div className="details">
              <div className="top">
                <h6>{message?.user?.displayName}</h6>
                <span>{moment.utc(message?.messageCreatedDate)?.local().format("DD-MM")} {moment.utc(message?.messageCreatedDate)?.local().format("HH:mm")}</span>
              </div>
              <HighlightText 
              showTextOnHover={true}
              displayText={message?.messageText} searchText={searchInput}/>
            </div>
          </div>
         </React.Fragment>
         )}
         {chatHistoryMessages && !chatHistoryMessages?.length &&(
          <p className="no-data">No data available</p>
         )}
        </div>
      </div>
    </div>
  ):<></>;
};

export default Messages;
